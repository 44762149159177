<template>
  <Layout class="main-cols">
    <div class="container is-fluid md:ml-0">
      <div class="columns">
        <div class="column">
          <p class="title has-text-grey-darker text-left">
            Solicitudes de dinero
          </p>
        </div>
        <div class="column text-right">
          <b-button
            :to="{
              name: activeTab == 2 ? 'solicitar_arrendamiento' : 'solicitar_disposicion',
              params: { pasiva: activeTab == 1 ? true : false }
            }"
            tag="router-link"
            :type="
              activeTab == 1 
                ? 'is-pasivo' 
                : activeTab == 2 ? 'is-arrendamiento' : 'is-primary'
            "
          >
            Crear {{ activeTab == 2 ? 'nuevo arrendamiento' : 'nueva disposición' }} 
          </b-button>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-12">
          <tabs :tabs="tabs" v-model="activeTab" />
        </div>
        <bienvenido-card
          :pasiva="activeTab == 1 ? true : false"
          :arrendamiento="activeTab == 2 ? true : false"
          v-if="getMe"
          :titulo="'¡Bienvenido a tus ' + titulo + ' ' + getMe.alias + '!'"
          descripcion="Aquí podrás acceder a todas las solicitudes de disposición para
          acceder a cada detalle y cambiar su estado, filtrarlas por estado y
          ver las disposiciones creadas recientemente."
        />
        <p
          class="font-bold mt-4 text-2xl has-text-grey-darker"
          v-if="disposicionCreada != null"
        >
          Disposición reciente
        </p>
        <DisposicionCard
          v-if="disposicionCreada != null"
          @hideDisposicion="hideDisposicion"
          :creada="true"
          class="w-full mt-2"
          :disposicion="disposicionCreada"
        />
        <div class="column is-12">
          <div class="columns">
            <div class="column is-9">
              <Filtros
                v-if="
                  getDisposiciones &&
                    Array.isArray(getDisposiciones.disposiciones)
                "
                :disposiciones="getDisposiciones.disposiciones"
                :activeTab="Number.parseInt(activeTab)"
                :tipo_disposicion="tipo_disposicion"
                :pagina="currentPage"
                @togglecheck="togglecheck"
              />
            </div>
            <div
              class="column is-3 flex items-center mt-6 text-right justify-end"
            >
              <b-button
                :type="
                  activeTab == 1 
                    ? 'is-pasivo' 
                    : activeTab == 2 ? 'is-arrendamiento' : 'is-primary'
                "
                outlined
                @click="toggleOperacionesMasivas"
                >{{ textOperacionMasiva }}</b-button
              >
            </div>
          </div>
        </div>
        <div class="column is-12 text-left mb-8" v-if="getProcesarOperaciones">
          <manejador-status
            :status="
              this.getFiltros.find(filtro => filtro.filtro == 'Estatus').nombre
            "
            :pasivo="activeTab == 1 ? true : false"
            :disposicion_id="this.disposiciones_checked"
            :tipo_disposicion="tipo_disposicion"
          />
        </div>
        <div
          class="column is-12"
          v-if="getDisposiciones != null && !getProcesarOperaciones"
        >
          <div
            class="has-background-white-ter has-border-radius p-10 text-left"
          >
            <div
              class="flex items-center"
              :class="{ 'mb-8': operaciones_masivas == false }"
            >
              <div v-if="operaciones_masivas">
                <b-checkbox size="is-large" v-model="checkbox_all" />
              </div>
              <p
                class="text-2xl text-left has-text-grey-darker font-bold inline-block"
              >
                {{ sizeDisposiciones }} {{ titulo }}
              </p>
            </div>
            <p v-if="operaciones_masivas" class="mb-8">
              {{ disposiciones_checked.length }}
              {{
                disposiciones_checked.length == 1
                  ? "disposición seleccionada"
                  : "disposiciones seleccionadas"
              }}
            </p>
            <div v-if="getDisposicionesFiltradas">
              <disposicion-card
                :disposicion="disposicion"
                v-for="disposicion in getDisposicionesFiltradas.disposiciones"
                :key="disposicion.id"
                :check="operaciones_masivas"
                :check-active="
                  disposiciones_checked.find(disp => disp.id == disposicion.id)
                    ? true
                    : false
                "
                @checked="checkGlobal"
              />
            </div>
            <div v-else>
              <disposicion-card
                :disposicion="disposicion"
                v-for="disposicion in getDisposiciones.disposiciones"
                :key="disposicion.id"
                :check="operaciones_masivas"
              />
            </div>
            <empty-state
              v-if="getDisposiciones != null && sizeDisposiciones == 0"
              imagen="/images/empty_state_disposiciones.svg"
              :titulo="
                getDisposicionesFiltradas != null
                  ? 'No tienes ' + titulo + ' con este filtro'
                  : 'No tienes ' + titulo
              "
              :subtitulo="
                getDisposicionesFiltradas != null
                  ? 'Prueba seleccionando otro filtro'
                  : 'Prueba seleccionando otra cartera'
              "
            />
          </div>
        </div>
        <div class="column is-12 text-left">
          <small class="block">mostrando {{ per_page }} resultados</small>
        </div>
        <b-pagination
          class="mb-8 pl-3"
          :total="sizeDisposiciones"
          :current.sync="currentPage"
          :range-before="2"
          :range-after="2"
          :per-page="per_page"
          icon-prev="chevron-left"
          icon-next="chevron-right"
          aria-next-label="Página siguiente"
          aria-previous-label="Página anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          v-if="!getProcesarOperaciones"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import DisposicionCard from "@/components/cards/DisposicionCard";
import BienvenidoCard from "@/components/cards/BienvenidoCard";
import Filtros from "@/components/generals/filtros/FiltrosDisposicion";
import EmptyState from "@/components/generals/EmptyState";
import Tabs from "@/components/generals/Tabs";
import ManejadorStatus from "@/components/Disposicion/Status/ManejadorStatus";
import { getLocalParam } from "@/vendors/helpers";

export default {
  name: "new_client",
  data() {
    return {
      activeTab: 0,
      tipo_disposicion: null,
      ocultada: false,
      currentPage: 1,
      operaciones_masivas: false,
      disposiciones_checked: [],
      checkbox_all: false,
      per_page: getLocalParam("paginador") || 20,
      tabs: [
        {
          label: "Solicitudes de cartera activa"
        },
        {
          label: "Solicitudes de cartera pasiva"
        },
        {
          label: "Solicitudes de arrendamiento"
        },
        {
          label: "Solicitudes bursatilizadas"
        },
        {
          label: "Todas"
        }
      ]
    };
  },
  components: {
    DisposicionCard,
    BienvenidoCard,
    Filtros,
    EmptyState,
    ManejadorStatus,
    Tabs
  },
  watch: {
    activeTab: {
      handler(val) {
        if (val == null) {
          return;
        }
        switch (parseInt(val)) {
          case 0:
            this.tipo_disposicion = "activas";
            break;
          case 1:
            this.tipo_disposicion = "pasivas";
            break;
          case 2:
            this.tipo_disposicion = "arrendamiento";
            break;
          case 3:
            this.tipo_disposicion = "bursa";
            break;
          case 4:
            this.tipo_disposicion = null;
            break;
        }
        this.$store.dispatch("disposicion/getDisposiciones", {
          tipo: this.tipo_disposicion
        });
        this.$store.dispatch("disposicion/clearDisposicionesFiltradas");
      },
      immediate: true
    },
    filtros: function() {
      this.operaciones_masivas = false;
      this.$store.dispatch("disposicion/changeProcesarOperaciones", false);
    },
    checkbox_all: function(val) {
      if (val == true) {
        this.disposiciones_checked.push(
          ...this.getDisposicionesFiltradas.disposiciones
        );
        this.disposiciones_checked = this.disposiciones_checked.filter(
          (disp, index, self) => self.findIndex(d => d.id === disp.id) === index
        );
      }
      if (val == false) {
        let disp_id = this.getDisposicionesFiltradas.disposiciones.map(
          a => a.id
        );
        this.disposiciones_checked = this.disposiciones_checked.filter(
          ({ id }) => !disp_id.includes(id)
        );
      }
    }
  },
  methods: {
    // Oculta la disposición recientemente creada
    hideDisposicion() {
      this.ocultada = true;
    },
    // Activa / desactiva el check de disposiciones para operaciones masivas
    togglecheck(val) {
      this.checkbox_all = val;
    },
    //Activa el procceso de operaciones masivas
    toggleOperacionesMasivas() {
      if (this.getFiltros != null) {
        let filtros = this.getFiltros.map(filtro => {
          if (
            filtro.filtro_id == "credito_status_id" ||
            filtro.filtro_id == "productos_financieros_id"
          ) {
            return filtro;
          }
        }).filter(Boolean);

        if (filtros.length < 2) {
          this.notification(
            "warning",
            "Operaciones masivas",
            `Para activar las operaciones masivas es necesario seleccionar solo un filtro de estatus y solo un filtro de producto financiero`
          );
          return;
        }
      }
      if (this.getFiltros === null) {
        this.notification(
          "warning",
          "Operaciones masivas",
          `Se necesita filtrar por un estatus y por un producto financiero para activar las operaciones masivas`
        );
        return;
      }
      if (this.getProcesarOperaciones) {
        this.$store.dispatch("disposicion/changeProcesarOperaciones", false);
        return;
      }
      if (!this.operaciones_masivas) {
        this.operaciones_masivas = true;
      } else {
        this.$store.dispatch("disposicion/changeProcesarOperaciones", true);
      }
    },
    /*Viene de la card de disposición, al ser marcada por el checkbox, asi que se 
      comprueba si no estaba marcada previamentey la mete en un arreglo de disposiciones_checked
      @val(Obj) objeto de la disposición
    */
    checkGlobal(val) {
      let repetido = this.disposiciones_checked.filter(
        disp => disp.id == val.disposicion.id
      );
      if (repetido.length == 0) {
        this.disposiciones_checked.push(val.disposicion);
      } else if (val.status == false) {
        let index = this.disposiciones_checked.findIndex(
          disp => disp.id == val.disposicion.id
        );
        this.disposiciones_checked.splice(index, 1);
      }
    }
  },
  computed: {
    ...mapGetters(["getMe", "getAyudaActual"]),
    ...mapGetters("disposicion", [
      "getDisposiciones",
      "getDisposicionesFiltradas",
      "getDisposicion",
      "getFiltros",
      "getProcesarOperaciones"
    ]),
    filtros() {
      return this.getFiltros;
    },
    textOperacionMasiva() {
      if (!this.operaciones_masivas) {
        return "Habilitar operaciones masivas";
      } else if (this.getProcesarOperaciones == true) {
        return "Editar operaciones masivas";
      } else if (this.operaciones_masivas) {
        return "Procesar operaciones masivas";
      }
      return "";
    },
    titulo() {
      let nombre = null;
      switch (Number.parseInt(this.activeTab)) {
        case 0:
          nombre = "Solicitudes de cartera activa";
          break;
        case 1:
          nombre = "Solicitudes de cartera pasiva";
          break;
        case 2:
          nombre = "Solicitudes de arrendamiento";
          break;
        case 3:
          nombre = "Solicitudes de cartera bursatilizada";
          break;
        case 4:
          nombre = "Todas";
          break;
      }
      return nombre;
    },
    sizeDisposiciones() {
      if (this.getDisposicionesFiltradas) {
        return this.getDisposicionesFiltradas.total;
      } else if (this.getDisposiciones) {
        return this.getDisposiciones.total;
      } else {
        return 0;
      }
    },
    disposicionCreada() {
      if (
        this.getDisposicion != null &&
        this.getDisposiciones != null &&
        this.ocultada == false
      ) {
        return this.getDisposiciones.disposiciones.find(disp => {
          return disp.id == this.getDisposicion.id;
        });
      }
      return null;
    }
  },
  mounted() {
    this.$store.dispatch("lineascredito/resetState");
    
    if (this.getDisposicion != null) {
      this.activeTab = this.getDisposicion.pasiva ? 1 : 0;
      this.tipo_disposicion = this.getDisposicion.pasiva
        ? "pasivas"
        : "activas";
    } else {
      this.activeTab = 0;
      this.tipo_disposicion = "activas";
    }
  }
};
</script>
