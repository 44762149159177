<template>
  <div
    class="has-border-radius p-3 text-left mb-8 relative disposicion-card columns is-mobile"
    :class="{
      'has-background-primary-04 creada mx-0': creada,
      'has-background-grey-lighter': !creada
    }"
  >
    <div class="column is-1 flex items-center justify-center" v-if="check">
      <div class="field">
        <b-checkbox size="is-large" v-model="checkbox" />
      </div>
    </div>
    <div class="column">
      <router-link
        :to="'/disposicion/' + disposicion.id"
        :disabled="check"
        :event="!check ? 'click' : ''"
        :style="check ? 'cursor: default' : ''"
      >
        <div v-if="creada">
          <b-tooltip
            label="Ocultar"
            type="is-dark"
            class="absolute right-0 top-0 pt-4 pr-4"
            position="is-left"
          >
            <b-icon
              icon="times-circle"
              class="close-btn"
              @click.prevent.native="$emit('hideDisposicion')"
            />
          </b-tooltip>
        </div>
        <p class="has-text-grey-darker font-bold md:text-xl text-2xl">
          {{ arrendamiento ? "Arrendamiento" :"Disposición" }} #{{ idToShow(disposicion) }}
          <span v-show="disposicion.linea_credito_id">
            de la línea
            <router-link
              :to="'linea/' + disposicion.linea_credito_id"
              :class="{
                'has-text-primary-darker': disposicion.pasiva == 0,
                'has-text-pasivo': disposicion.pasiva == 1
              }"
            >
              #{{ disposicion.linea_credito_id }}
            </router-link>
          </span>
        </p>
        <div class="columns mt-1 mb-0 md:mr-8 is-multiline">
          <div class="column">
            <div class="columns is-multiline has-text-grey">
              <div class="column">
                <p class="text-xs has-text-grey-light">ACREDITADO</p>
                <p class="has-text-grey-darker">
                  <preview
                    v-if="disposicion.acreditado"
                    :id="disposicion.acreditado.id"
                    tipo="persona"
                    >{{ disposicion.acreditado.nombre_completo.split("|")[0] }}
                  </preview>
                  <span v-else>No definido aún</span>
                </p>
              </div>
              <div class="column">
                <p class="text-xs has-text-grey-light">EJECUTIVO</p>
                <p class="has-text-grey-darker">
                  <preview
                    v-if="disposicion.ejecutivo"
                    :id="disposicion.ejecutivo.id"
                    tipo="persona"
                    >{{ disposicion.ejecutivo.nombre_completo.split("|")[0] }}
                  </preview>
                  <span v-else>No definido aún</span>
                </p>
              </div>
              <div class="column">
                <p class="text-xs has-text-grey-light">FOLIO FONDEADOR</p>
                <p class="has-text-grey-darker">
                  {{ folioFondeador }}
                </p>
              </div>
              <div class="column">
                <p class="text-xs has-text-grey-light">PRODUCTO FINANCIERO</p>
                <p class="has-text-grey-darker">
                  {{ disposicion.producto_financiero.alias }}
                </p>
              </div>
              <div class="column">
                <p class="text-xs has-text-grey-light">FECHA DE ENTREGA</p>
                <p class="has-text-grey-darker">
                  {{ disposicion.fecha_entrega | moment("DD-MM-YYYY") }}
                </p>
              </div>
              <div class="column">
                <p class="text-xs has-text-grey-light">FECHA DE VENCIMIENTO</p>
                <p class="has-text-grey-darker">
                  {{ disposicion.fecha_vencimiento | moment("DD-MM-YYYY") }}
                </p>
              </div>
              <div class="column">
                <p class="text-xs has-text-grey-light">ESTATUS</p>
                <b-tag :type="statusBadge">
                  {{
                    disposicion.credito_status
                      ? disposicion.credito_status.nombre
                      : "Sin estatus"
                  }}
                </b-tag>
              </div>
              <div class="column">
                <p class="text-xs has-text-grey-light">IVA</p>
                <p class="has-text-grey-darker">
                  {{ disposicion.iva_interes_ordinario ? "Sí" : "No" }}
                </p>
              </div>
            </div>
          </div>
          <div class="column is-12-desktop  is-5-widescreen is-4-fullhd">
            <p
              class="text-2xl font-bold text-left md:text-right"
              :class="{
                'md:text-5xl': !disposicion.saldos || !disposicion.credito_status.activo,
                'has-text-primary': disposicion.pasiva == 0 && !arrendamiento,
                'has-text-arrendamiento': disposicion.pasiva == 0 && arrendamiento,
                'has-text-pasivo': disposicion.pasiva == 1
              }"
            >
              <b-tooltip label="Monto Solicitado" type="is-dark">
                {{ disposicion.capital | currency | hp }}
              </b-tooltip>
              <b-tooltip :label="disposicion.moneda.nombre" type="is-dark">
                <span class="text-sm moneda-clave">{{
                  disposicion.moneda.clave
                }}</span>
              </b-tooltip>
            </p>
            <p
              v-if="disposicion.saldos && disposicion.credito_status.activo"
              class="text-2xl md:text-5xl font-bold text-left md:text-right"
              :class="{
                'has-text-success' : 
                  disposicion.saldos.vencido_exigible <= 0 &&
                  disposicion.saldos.vencido_por_traspasar <= 0 &&
                  disposicion.saldos.vencido_no_exigible <= 0,
                'has-text-warning' : 
                  disposicion.saldos.vencido_por_traspasar > 0 &&
                  disposicion.saldos.vencido_exigible <= 0 &&
                  disposicion.saldos.vencido_no_exigible <= 0,
                'has-text-danger' : 
                  disposicion.saldos.vencido_exigible > 0 ||
                  disposicion.saldos.vencido_no_exigible > 0
              }"
            >
              <b-tooltip label="Monto disponible" type="is-dark">
                {{ disposicion.saldos.total | currency | hp }}
              </b-tooltip>
              <b-tooltip :label="disposicion.moneda.nombre" type="is-dark">
                <span class="text-xl moneda-clave">{{
                  disposicion.moneda.clave
                }}</span>
              </b-tooltip>
            </p>
          </div>
        </div>
        <b-icon
          v-if="!check"
          icon="chevron-right"
          class="absolute right-0 center-y invisible md:visible"
          size="is-medium"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "disposicionCard",
  data() {
    return {
      checkbox: false
    };
  },
  props: {
    disposicion: Object,
    creada: {
      type: Boolean,
      default: false
    },
    check: {
      type: Boolean,
      default: false
    },
    arrendamiento: {
      type: Boolean,
      default: false
    },
    checkActive: Boolean
  },
  watch: {
    checkbox: function() {
      if (this.checkbox != this.checkActive) this.sendToParent();
    },
    checkActive: function() {
      this.checkbox = this.checkActive;
    }
  },
  computed: {
    statusBadge() {
      if (this.disposicion.credito_status) {
        switch (this.disposicion.credito_status.nombre) {
          case "Prospecto":
            return "is-primary";
          case "En espera":
            return "is-secondary";
          case "Autorizado":
            return "is-success";
          case "Entregado":
            return "is-success";
          case "Declinado":
            return "is-danger";
          default:
            return "";
        }
      }
      return "";
    },
    folioFondeador() {
      if (this.disposicion.pasiva) {
        return this.disposicion.identificador_fondeador;
      }

      const fondeoConIdentificador = this.disposicion.disposicion_fondeos.find(fondeo => fondeo.disposicion && fondeo.disposicion.identificador_fondeador);
      if (fondeoConIdentificador) {
        return fondeoConIdentificador.disposicion.identificador_fondeador;
      }

      return 'No disponible';
    }
  },
  methods: {
    //Envia al parent que la disposicion ha sido checkeada
    sendToParent() {
      this.$emit("checked", {
        disposicion: this.disposicion,
        status: this.checkbox
      });
    }
  },
  mounted() {
    this.checkbox = this.checkActive;
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
.moneda-clave {
  line-height: 3rem;
  align-self: flex-end;
}
.disposicion-card {
  cursor: pointer;
  border: 4px solid var(--grey-lighter);
  &.creada {
    border: 0px solid transparent !important;
    &:hover {
      background-color: rgba($primary, 0.2) !important;
    }
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: var(--white-ter) !important;
  }
}
.close-btn {
  transition: all 0.2s ease-in-out;
  &:hover {
    color: $danger;
  }
}
@media (max-width: 768px) {
  .text-5xl {
    font-size: 2rem !important;
  }
  .text-xl {
    font-size: 0.8rem !important;
  }
}
</style>
