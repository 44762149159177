<template>
  <div>
    <div class="max-h-22rem overflow-y-auto">
      <div
        class="has-background-white has-border-radius shadow-none p-5 columns mb-4 is-multiline m-0"
        v-for="(disposicion, index) in disposiciones"
        :key="index"
      >
        <div class="column is-12 pb-0">
          <p class="text-xl">
            <b>Línea</b>
            #{{ disposicion.linea_credito[0].id }}
          </p>
        </div>
        <div class="column is-12 pb-0">
          <p class="font-bold" :class="{ 'text-sm': esGrupal(disposicion), 'text-xl': !esGrupal(disposicion) }">
            {{ disposicion.acreditado.nombre_completo }} 
            {{ esGrupal(disposicion) && disposicion.linea_credito[0].grupo_credito
              ? ` - ${disposicion.linea_credito[0].grupo_credito.nombre} (${ disposicion.linea_credito[0].grupo_credito.sucursal ? disposicion.linea_credito[0].grupo_credito.sucursal.nombre : 'Sin sucursal' })` 
              : '' 
            }}
          </p>
        </div>
        <div class="column pb-0">
          <p class="text-xl">
            <b>Folio</b>
            #{{ idToShow(disposicion) }}
          </p>
        </div>
        <div class="column text-right pb-0">
          <p class="text-2xl font-bold">
            {{ disposicion.capital | currency | hp }}
            <span class="text-sm font-200">{{ disposicion.moneda.clave }}</span>
          </p>
        </div>
        <div class="column is-12 pt-0">
          <p>{{ disposicion.producto_financiero.alias }}</p>
        </div>
        <div v-if="disposicion.interes_ordinario" class="column is-6">
          <p class="font-bold">{{ disposicion.interes_ordinario.tasa_base ? disposicion.interes_ordinario.tasa_base.nombre : 'Tasa Fija' }}</p>
          <p>{{ disposicion.interes_ordinario.cantidad }}%</p>
        </div>
        <div class="text-xl font-bold column is-6">
          <p>{{ `${disposicion.plazo_dias} días` }}</p>
        </div>
      </div>
    </div>
    <div class="mx-0 mt-4 columns rule-grey">
      <div class="column">
        <p class="text-2xl font-bold">Total:</p>
      </div>
      <div class="column text-right">
        <p class="font-bold text-2xl">{{ total | currency | hp }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "listDisposicionCards",
  props: {
    disposiciones: Array,
    total: Number
  },
  methods: {
    esGrupal(disposicion) {
      return disposicion.linea_credito[0]?.grupo_credito_id;
    }
  }
};
</script>
